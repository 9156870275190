.purple-input {
    color: white;
    border: 2px solid #8707ff;
    border-radius: 10px;
    /* padding: 10px 25px; */
    background: transparent;
    /* max-width: 190px; */
  }
  
  .purple-input:active {
    box-shadow: 2px 2px 15px #8707ff inset;
  }
  
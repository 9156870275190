.card {
     background: linear-gradient(0deg, #000, #272727);
    border-radius: 8px;
    z-index: 1;
    width: '100%';
    height: '400px';  
}
  
  .tools {
    display: flex;
    align-items: center;
    padding: 9px;
  }
  
  .circle {
    padding: 0 4px;
  }
  
  .box {
    display: inline-block;
    align-items: center;
    width: 10px;
    height: 10px;
    padding: 1px;
    border-radius: 50%;
  }
  
  .red {
    background-color: #ff605c;
  }
  
  .yellow {
    background-color: #ffbd44;
  }
  
  .green {
    background-color: #00ca4e;
  }
  
.input {
    display: flex;
    flex-direction: column;
    width: 100%;
/* background-color: rgb(39 39 42); */
/* background-color: #0D1117; */

    justify-content: center;
    /* border-radius: 5px */
  }
  
  .value {
    background-color: transparent;
    /* border-top: rgba(89, 89, 89, 0.369) 5px solid;
    border-top-left-radius: 30px;
      */
    /* border-bottom: rgba(255, 255, 255, 0.119) 1px solid; */
    padding: 10px;
    color: white;
    display: flex;
    position: relative;
    gap: 5px;
    cursor: pointer;
    border-radius: 4px;
    /* vertically center elements */
    align-items: center;
  }
  
  .value:not(:active):hover,
  .value:focus {
    background-color: #000000;
  }
  
  .value:focus,
  .value:active {
    background-color: #000000;
    outline: none;
  }
  
  .value::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -10px;
    width: 5px;
    height: 80%;
    /* background-color: #2F81F7; */
    border-radius: 5px;
    opacity: 0;
  }
  
  .value:focus::before,
  .value:active::before {
    opacity: 1;
  }
  
  .value svg {
    width: 15px
  }